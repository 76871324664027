.quill .ql-toolbar {
  background-color: #25262b;
  border-color: #373a40;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.quill .ql-container {
  background-color: #25262b;
  /*border-color: #373a40;*/
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 14px;
  border: 2px solid #373a40;
}

.quill .ql-toolbar{
  border: 2px solid #373a40;
}

.quill-light .ql-toolbar {
  background-color: #fff;
  border-color: #ced4da;
}

.quill-light .ql-container {
  background-color: #fff;
  border-color: #ced4da;
}

.quill-error .ql-container {
  border-color: #e03131;
}

.quill-error .ql-toolbar {
  border-top-color: #e03131;
  border-left-color: #e03131;
  border-right-color: #e03131;
}


.quill .ql-toolbar .ql-formats button{
  border: 1px solid #373a40;
  border-radius: 4px;
}

.quill .ql-toolbar .ql-formats .ql-picker .ql-picker-label{
  border: 1px solid #373a40;
  border-radius: 4px;
}

.quill .ql-toolbar .ql-formats .ql-active svg {
  fill: #9933ff !important;
  color: #9933ff !important;
}
